import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "gcpAnalytic",
  pluralName: "gcp_analytics",
  pathName: "gcp_analytics",
  endpoint: "admin/gcp_analytics",
  serverName: "gcp_analytic",
  serverPluralName: "gcp_analytics",
  displayName: "Gcp Analytics",
  titleField: "name",
  showSearch: true,
  fields: [
    {
      identifier: "result",
      label: { en: "Products per GCP", ar: "المنتجات لكل GCP" },
      accessor: (dataObject) => dataObject.result || 0,
      inList: true,
      type: "string",
    },
  ],
});

import { Entity } from "@thewebops/admin-panel";

export default authConfig =>
  new Entity({
    name: "forgetPassword",
    title: "Forgot Password",
    subTitle: `Enter the email associated with your ${authConfig.displayName} account, a password reset email will be sent to you!`,
    path: "/forgetPassword",
    actionLabel: "Request Reset email",
    action: "create",
    fields: [
      {
        label: "Email",
        required: true,
        type: "email",
        identifier: "email",
      },
    ],
    requestConfig: {
      url: `/${authConfig.serverName}/passwords`,
      method: "post",
      successMessage: "Email has been sent",
      errorMessage: "Couldn't send email",
    },
  });

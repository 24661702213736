import moment from "moment";

const edaStatusHistoryTable = [
  {
    identifier: "paper_trail/versions",
    label: "EDA Status History",
    accessor: (dataObject) => (dataObject?.eda_status_versions ? dataObject?.eda_status_versions : []),
    type: "table",
    dependsOn: "product_type",
    dependsOnValue: "pharmaceutical",
    hideInCreate: true,
    hideInEdit: true,
    lgCol: 12,
    mdCol: 12,
    fields: [
      {
        identifier: "id",
        label: "Version ID",
        accessor: "id",
        inList: true,
        hideInCreate: true,
        hideInEdit: true,
      },
      {
        identifier: "whodunnit",
        accessor: (dataObject) => (dataObject?.whodunnit ? dataObject?.whodunnit : "_"),
        label: "Updater",
        type: "string",
        hideInCreate: true,
        hideInEdit: true,
        inList: true,
      },
      {
        identifier: "event",
        accessor: "event",
        label: "Event",
        hideInCreate: true,
        hideInEdit: true,
        inList: true,
      },
      {
        identifier: "source",
        accessor: (dataObject) => (dataObject?.source ? dataObject?.source : "_"),
        label: "Source",
        hideInCreate: true,
        hideInEdit: true,
        inList: true,
        type: "string",
      },
      {
        identifier: "status",
        accessor: (dataObject) =>
          dataObject?.object_changes?.status ? dataObject?.object_changes?.status.slice(-1)[0] : "_",
        label: "Status",
        hideInCreate: true,
        hideInEdit: true,
        inList: true,
        type: "string",
      },
      {
        identifier: "rejection_reasons",
        accessor: (dataObject) =>
          dataObject?.object_changes?.rejection_reasons
            ? dataObject?.object_changes?.rejection_reasons.slice(-1)[0].join(", ")
            : "_",
        label: "Rejection Reasons",
        hideInCreate: true,
        hideInEdit: true,
        inList: true,
        type: "string",
      },
      {
        identifier: "created_at",
        accessor: (dataObject) => (dataObject?.created_at ? moment(dataObject.created_at).format("lll") : null),
        label: "Date",
        hideInCreate: true,
        hideInEdit: true,
        inList: true,
      },
    ],
    tableConfig: {
      name: "product",
      type: "static",
      import: undefined,
    },
  },
];

export default edaStatusHistoryTable;

import { takeLatest, call, put, select } from "redux-saga/effects";
import { request } from "@thewebops/admin-panel";
import actions from "./actions";
import { showNotification, filtersToAttributes, getEntity } from "@thewebops/admin-panel";
import { saveAs } from "file-saver";
import i18n from "i18next";

function* publishAllProducts(action) {
  const { item, entity } = action.payload;
  const { success, failure } = actions.publishAllProducts;
  try {
    const response = yield call(request, {
      url: `/admin/companies/${item.id}/products/`,
      method: "put",
      authenticated: true,
    });

    yield put(success({ entity, company: response.data.company }));
    showNotification(null, "success", i18n.t("notifications:editSuccess"));
  } catch (error) {
    yield put(failure());
    showNotification(null, "error");
  }
}

function* watchPublishAllProducts() {
  const { request } = actions.publishAllProducts;
  yield takeLatest(request, publishAllProducts);
}

function* downloadImportTemplate(action) {
  const { success, failure } = actions.downloadImportTemplate;
  try {
    const response = yield call(request, {
      url: `admin/companies/imports`,
      method: "get",
      authenticated: true,
      isDownloading: true,
    });
    yield call(saveAs, response.data, "import_template.xlsx");
    yield put(success());
  } catch (error) {
    showNotification(null, "error", JSON.stringify(error.message));
    yield put(failure());
  }
}

function* exportCompanies(action, state) {
  const { entity, customEntityName } = action.payload;
  const { success, failure } = actions.exportCompanies;
  try {
    const storeEntity = yield select(getEntity, customEntityName || entity.pluralName);

    let { company_type, inactive } = {
      ...filtersToAttributes(entity, { ...storeEntity, ...action.payload }),
    };
    yield call(request, {
      url: "admin/export_sheets",
      data: {
        company_type,
        inactive,
        query: storeEntity.query,
        export_type: "companies",
      },
      method: "post",
      authenticated: true,
    });
    yield put(success());
    showNotification(null, "success", i18n.t("notifications:export"));
  } catch (error) {
    yield put(failure());

    showNotification(null, "error");
  }
}

function* watchEntityExportCompanies() {
  const { request } = actions.exportCompanies;

  yield takeLatest(request, exportCompanies);
}

function* watchEntityDownloadImportTemplate() {
  const { request } = actions.downloadImportTemplate;
  yield takeLatest(request, downloadImportTemplate);
}

export default [watchEntityDownloadImportTemplate, watchEntityExportCompanies, watchPublishAllProducts];

import { takeLatest, call, put } from "redux-saga/effects";
import { request } from "@thewebops/admin-panel";
import actions from "./actions";
import { showNotification } from "@thewebops/admin-panel";
import { saveAs } from "file-saver";

function* downloadImportTemplate(action) {
  const { success, failure } = actions.downloadImportTemplate;
  try {
    const response = yield call(request, {
      url: `admin/brands/download_template`,
      method: "get",
      authenticated: true,
      isDownloading: true,
    });
    yield call(saveAs, response.data, "import_template.xlsx");
    yield put(success());
  } catch (error) {
    showNotification(null, "error", JSON.stringify(error.message));
    yield put(failure());
  }
}

function* watchEntityDownloadImportTemplate() {
  const { request } = actions.downloadImportTemplate;
  yield takeLatest(request, downloadImportTemplate);
}

export default [watchEntityDownloadImportTemplate];

import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "dropdownCategory",
  pluralName: "dropdownCategories",
  pathName: "dropdownCategories",
  endpoint: "/admin/dropdown/categories",
  serverName: "category",
  serverPluralName: "categories",
  displayName: { en: "Categories", ar: "Categories" },
  allowCreate: false,
  allowEdit: false,
  allowDelete: false,
  showRowActions: false,
  showSearch: true,
  authorizedRoles: {
    entity: ["admin"],
  },
  isPassive: true,
  fields: [],
});

import React from "react";
import WarningMessage from "../components/warningMessage";

const migrateGcpsAction = [
  {
    identifier: "migrate_gcps",
    type: "formModal",
    active: (dataObject) => dataObject?.company_type == "account",
    label: "Migrate Gcps",
    entityConfig: {
      name: "company",
      fieldsOverrides: {
        warning_message: {
          formComponent: () => (
            <>
              <WarningMessage message="The active status of migrated GCPs will remain unchanged" />
            </>
          ),
          hideInEdit: false,
          lgCol: 12,
          mdCol: 12,
        },
        error_message: {
          hideInEdit: false,
          lgCol: 12,
          mdCol: 12,
        },
        gcp_ids: {
          lgCol: 8,
          mdCol: 8,
        },
        migrate_all: {
          lgCol: 4,
          mdCol: 4,
        },
        target_company_id: {
          lgCol: 12,
          mdCol: 12,
        },
      },
      referencedEntityField: "id",
      entityOverrides: {
        customEditEndpoint: ({ entity, data, id }) => {
          return `${entity.endpoint}/${id}/gcps/migrate`;
        },
        isFullWidth: true,
        customNotifications: {
          edit: {
            error: {
              title: "Gcps Migration Error",
              message: "Something went wrong while migrating the Gcps",
            },
            success: {
              title: "Success ",
              message: "Gcps Migrated Successfully",
            },
          },
        },
      },
    },
    formAction: "edit",
  },
];
export default migrateGcpsAction;

import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "categoryAnalytic",
  pluralName: "category_analytics",
  pathName: "category_analytics",
  endpoint: "admin/category_analytics",
  serverName: "category_analytic",
  serverPluralName: "category_analytics",
  displayName: "Category Analytics",
  titleField: "name",
  fields: [
    {
      identifier: "result",
      label: { en: "Products per Category", ar: "المنتجات لكل فئة" },
      accessor: "result",
      inList: true,
      type: "string",
    },
  ],
  filters: [
    {
      label: { en: "Category", ar: "التصنيف" },
      name: "category_id",
      type: "select",
      selectConfig: {
        optionsType: "dynamic",
        optionsEntity: {
          name: "dropdownCategory",
          labelField: "name",
          valueField: "id",
        },
        isSearchable: true,
      },
    },
  ],
});

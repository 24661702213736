import { Entity } from "@thewebops/admin-panel";
import actions from "./actions";
import reducers from "./reducers";
import sagas from "./sagas";

export default new Entity({
  name: "deletedProduct",
  pluralName: "deleted_products",
  pathName: "deleted_products",
  endpoint: "/admin/deleted_products",
  serverName: "deleted_product",
  serverPluralName: "deleted_products",
  displayName: "Deleted Products",
  customActions: actions,
  customReducers: reducers,
  customSagas: sagas,
  showSearch: true,
  globalActions: [
    {
      label: { en: "Download Delete sheet Template", ar: "تنزيل نموذج" },
      name: "downloadDeleteTemplate",
    },
  ],
  import: {
    label: "Upload file of deletion",
    enabled: true,
    extensions: ["xls", "application/vnd.openxmlformats-officedocument", "xlsx"],
    paramName: "file",
    method: "post",
    endpoint: "/admin/deleted_products",
  },
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      hideInShow: true,
    },
    {
      identifier: "product_id",
      label: "Product ID",
      accessor: "product_id",
      inList: true,
    },
    {
      identifier: "gtin",
      label: "GTIN",
      accessor: (dataObject) => dataObject?.gtin || "-",
      inList: true,
    },
    {
      identifier: "description",
      label: "Description",
      accessor: "description",
      inList: true,
      type: "string",
    },
    {
      identifier: "gcp_id",
      label: "GCP (gcp)",
      accessor: "gcp_id",
      inList: true,
      type: "string",
    },
    {
      identifier: "admin_id",
      label: "Admin",
      accessor: (dataObject) => dataObject?.admin?.name,
      type: "string",
      inList: true,
    },
  ],
  filters: [
    {
      label: "Admin",
      name: "admin_id",
      type: "select",
      selectConfig: {
        optionsType: "dynamic",
        optionsEntity: {
          name: "admin",
          labelField: "name",
          valueField: "id",
        },
      },
    },
  ],
});

import React, { useEffect, useMemo, useState } from "react";
import { Button, Upload } from "antd";
import { PaperClipOutlined } from "@ant-design/icons";
import { Form } from 'antd';
import { useTranslation } from "react-i18next";
import "../../style.css"

const CompaniesSheet = ({ field, label, onChange, state, data, errors, value, type, filePreview, excludeDay, format, ...props }) => {
  const { t } = useTranslation("companiesField");

  const handleChange = files => {
    const file = files.fileList[0];
    if (file) {
      // Removing uid generated by antd's upload component to ensure that jsonToFormData function will send the binary file only
      delete file.originFileObj.uid;
      onChange(field, file.originFileObj);
    }
  };

  const handleRemoveFile = () => {
    onChange(field, "");
  };

  return (
    <>
      <Form.Item
        name="url"
        style={{ fontWeight: "bold" }}
        label={field.label}
        labelAlign={"left"}
        rules={[{ required: true }]}
      >
        <Upload
          beforeUpload={() => { }}
          onChange={handleChange}
          onRemove={handleRemoveFile}
          onPreview={() => { }}
          maxCount={1}
          disabled={false}
          showUploadList={false}
          listType={""}
          
        >
          <Button icon={<PaperClipOutlined />}>
            {value ? t("change") : t("choose")} {t("file")}
          </Button>
        </Upload>

        {value && <div class="companiesSheetPreview"><PaperClipOutlined /> <a target="_blank" rel="noopener noreferrer">
          {value.name}
        </a></div>}

        {errors["companies_sheet"] && <div style={{ color: "#ff4d4f" }}>{
          errors["companies_sheet"] === "requiredFields"? "This field is required" : errors["companies_sheet"]
        }</div>}
      </Form.Item>
    </>
  );
};


export default CompaniesSheet;

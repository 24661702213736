import React, { Fragment, useEffect } from "react";
import { MaskedInput } from "antd-mask-input";

const FormattedTaxRegistrationNumber = ({ action, field, onChange, state, value, data, errors, ...props }) => {
  const handleInputChange = (e) => {
    onChange(field, e.unmaskedValue);
  };

  useEffect(() => {
    if (value) onChange(field, value.replaceAll("-", ""));
  }, []);

  const errorMessage = errors.tax_registration_number;

  return (
    <Fragment>
      <div style={{ paddingBottom: "8px" }}>
        <label style={{ fontWeight: "bold" }}>Tax Registration Number</label>
      </div>
      <MaskedInput
        value={value}
        mask={"000-000-000"}
        onChange={handleInputChange}
        style={errorMessage ? { borderColor: "#ff4d4f" } : {}}
      />
      {errorMessage && <div style={{ color: "#ff4d4f" }}>{errorMessage}</div>}
    </Fragment>
  );
};

export default FormattedTaxRegistrationNumber;

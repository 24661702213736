import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "dropdownUser",
  pluralName: "dropdownUsers",
  pathName: "dropdownUsers",
  endpoint: "/admin/dropdown/users",
  serverName: "user",
  serverPluralName: "users",
  displayName: { en: "Users", ar: "Users" },
  allowCreate: false,
  allowEdit: false,
  allowDelete: false,
  showRowActions: false,
  authorizedRoles: {
    entity: ["admin"],
  },
  isPassive: true,
  fields: [],
});

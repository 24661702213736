import { Entity } from "@thewebops/admin-panel";
import moment from "moment";
import actions from "./actions";
import reducers from "./reducers";
import sagas from "./sagas";
import historyTable from "../../components/historyTable";


const RESTRICTED_GCPS = ["622301170", "6224007216"];

export default new Entity({
  name: "gcp",
  pluralName: "gcps",
  pathName: "gcps",
  endpoint: "/admin/gcps",
  serverName: "gcp",
  serverPluralName: "gcps",
  displayName: "Gcp",
  allowShow: true,
  allowCreate: true,
  allowEdit: (dataObject) => !RESTRICTED_GCPS.includes(dataObject.code),
  allowDelete: true,
  showRowActions: true,
  showSearch: true,
  import: {
    enabled: true,
    extensions: ["xls", "csv", "application/vnd.openxmlformats-officedocument", "xlsx"],
    paramName: "file",
    method: "post",
    endpoint: "/admin/gcps/import",
  },
  customActions: actions,
  customReducers: reducers,
  customSagas: sagas,
  customNotifications: {
    import: {
      error: {
        title: "Error Importing Sheet",
        message: (dataObject) => {
          return dataObject.error.message;
        },
      },
    },
  },
  globalActions: [
    {
      label: { en: "Download import sheet Template", ar: "تنزيل نموذج" },
      name: "downloadImportTemplate",
    },
  ],
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "code",
      label: "Code",
      accessor: "code",
      inList: true,
      usedToShow: true,
      type: "string",
      required: true,
    },
    {
      identifier: "active_from",
      label: { en: "Active From", ar: "نشط من" },
      accessor: "active_from",
      inList: true,
      required: true,
      type: "date",
    },
    {
      identifier: "active_to",
      label: { en: "Active To", ar: "نشط حتى" },
      accessor: "active_to",
      inList: true,
      type: "date",
    },
    {
      identifier: "active",
      label: "Active",
      accessor: (dataObject) => (dataObject?.active ? true : false),
      inList: true,
      required: true,
      type: "select",
      options: [
        {
          label: { en: "Active", ar: "نشط" },
          value: true,
        },
        {
          label: { en: "Inactive", ar: "غير نشط" },
          value: false,
        },
      ],
    },
    {
      identifier: "company_id",
      label: { en: "Owner Company", ar: "الشركة المالكة" },
      accessor: (dataObject) => dataObject?.company?.name,
      formAccessor: (dataObject) => dataObject?.company?.id,
      type: "reference",
      inList: true,
      isSearchable: true,
      required: true,
      disabled: (action) => action === "edit",
      optionsEntity: {
        name: "company",
        labelField: "name",
        valueField: "id",
        displayAsLink: true,
        filterParams: [
          {
            name: "company_type",
            value: "account",
            type: "both",
          },
        ],
      },
    },
    {
      identifier: "sales_channel_gcps_attributes",
      label: { en: "Sales Channels", ar: "اختصار الشركة العالمي" },
      lgCol: 12,
      mdCol: 12,
      accessor: "sales_channel_gcps_attributes",
      type: "transferList",
      transferListConfig: {
        accessor: "sales_channel_gcps",
        showSearch: true,
      },
      optionsEntity: {
        name: "salesChannel",
        valueField: "id",
        labelField: "name",
        valueLabel: "sales_channel_id",
        displayAsLink: true,
        required: true,
        filterParams: [
          {
            name: "gcp_id",
            value: ["params", "id"],
            type: "both",
          },
        ],
      },
    },
    {
      identifier: "created_at",
      label: { en: "Created at", ar: "أنشئت في" },
      accessor: (dataObject) => moment(dataObject.created_at).format("DD-MM-YYYY hh:mm A"),
      inList: true,
      type: "date",
      hideInEdit: true,
      hideInCreate: true,
    },
    {
      identifier: "updated_at",
      label: { en: "Updated at", ar: "تم التحديث في" },
      accessor: (dataObject) => moment(dataObject.updated_at).format("DD-MM-YYYY hh:mm A"),
      inList: true,
      type: "date",
      hideInEdit: true,
      hideInCreate: true,
    },
    ...historyTable,
  ],
  filters: [
    {
      label: { en: "Active", ar: "نشط" },
      name: "active_status",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          {
            label: { en: "Active", ar: "نشط" },
            value: true,
          },
          {
            label: { en: "Inactive", ar: "غير نشط" },
            value: false,
          },
        ],
      },
    },

    {
      label: "Company",
      name: "company_id",
      type: "select",
      multiple: true,
      selectConfig: {
        optionsType: "dynamic",
        isSearchable: true,
        optionsEntity: {
          name: "company",
          labelField: "name",
          valueField: "id",
          filterParams: [
            {
              name: "company_type",
              value: ["account"],
              type: "both",
            },
          ],
        },
      },
    },
  ],
});

const getBaseUrl = () => {
  switch (process.env.REACT_APP_ENV || "development") {
    case "development":
      return "http://localhost:3000/v1";
    case "staging":
      return "https://staging-external-api.gs1eg.org/v1";
    case "production":
      return "https://external-api.gs1eg.org/v1";
    case "stg-dev":
      return "https://gs1-gi-stg-dev.azurewebsites.net/v1";
    default:
      return "APP_API_URL";
  }
};

export const API_BASE_URL = getBaseUrl();

export const GOOGLE_API_KEY = "AIzaSyBjZcO-lMRLE7w9onAbHa3NrnkKuw3HSs0";

import React from "react";
import InfoMessage from "./Company/components/infoMessage";
import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "salesChannel",
  pluralName: "salesChannels",
  pathName: "sales_channels",
  endpoint: "/admin/sales_channels",
  serverName: "sales_channel",
  serverPluralName: "sales_channels",
  displayName: "Sales Channel",
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  showSearch: true,
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "name",
      label: { en: "Name", ar: "الاسم" },
      accessor: "name",
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "remote_id",
      label: { en: "Remote ID", ar: "رقم تعريف المصدر" },
      accessor: "remote_id",
      inList: true,
      type: "string",
      hideInEdit: true,
      required: true,
    },
    {
      identifier: "sales_channel_users_attributes",
      label: { en: "Users", ar: "المستخدمين" },
      lgCol: 12,
      mdCol: 12,
      accessor: "sales_channel_users_attributes",
      type: "transferList",
      transferListConfig: {
        accessor: "sales_channel_users",
      },
      optionsEntity: {
        name: "user",
        valueField: "id",
        labelField: "user_name",
        valueLabel: "user_id",
        displayAsLink: true,
        required: true,
        filterParams: [
          {
            name: "sales_channel_id",
            value: ["params", "id"],
            type: "both",
          },
        ],
      },
    },
    {
      identifier: "sales_channel_gcps_attributes",
      label: { en: "Gcps", ar: "اختصار الشركة العالمي" },
      lgCol: 12,
      mdCol: 12,
      accessor: "sales_channel_gcps_attributes",
      type: "transferList",
      transferListConfig: {
        accessor: "sales_channel_gcps",
        showSearch: true,
      },
      optionsEntity: {
        name: "gcp",
        valueField: "id",
        labelField: "code",
        valueLabel: "gcp_id",
        displayAsLink: true,
        required: true,
        filterParams: [
          {
            name: "sales_channel_id",
            value: ["params", "id"],
            type: "both",
          },
        ],
      },
    },
    {
      formComponent: () => (
        <>
          <InfoMessage message="Search by a GCP code or a company name" />
        </>
      ),
    },
  ],
});

import React, { Fragment, useState, useEffect } from "react";
import { DatePicker } from "antd";
import moment from "moment";
import locale from "./localizationDatePicker";

const CustomDatePicker = ({ field, label, onChange, state, data, errors, value, excludeDay, format, ...props }) => {
  const [date, setDate] = useState(value || "");

  useEffect(() => {
    if (value === null) setDate("");
    else setDate(value);
  }, [value]);

  const handleChange = (date) => {
    let _date = date ? moment(date).format(excludeDay ? "YYYY-MM" : moment.HTML5_FMT.DATE) : "";
    setDate(_date);
    onChange(field, _date);
  };

  const errorMessage = errors.discontinued_at;

  return (
    <Fragment>
      <div style={{ paddingBottom: "8px" }}>
        <label style={{ fontWeight: "bold" }}>Discontinued At</label>
      </div>
      <DatePicker
        locale={locale}
        onChange={handleChange}
        value={date && moment(date)}
        format={format || "MMMM Do, YYYY"}
        picker={excludeDay ? "month" : "date"}
        inputReadOnly={true}
        allowClear={true}
        disabledDate={false}
        style={errorMessage ? { borderColor: "#ff4d4f" } : {}}
      />
      {errorMessage && <div style={{ color: "#ff4d4f" }}>{errorMessage}</div>}
    </Fragment>
  );
};

export default CustomDatePicker;

import { Entity } from "@thewebops/admin-panel";

export default (authConfig) =>
  new Entity({
    identifier: "companyProductsReports",
    name: "companyProductsReports",
    title: "Export Company's Products",
    subTitle: "Export a Sheet Contains the Products of the Desired Company",
    hideFromNavbar: true,
    path: "companyProductsReports",
    isFullWidth: true,
    submitButtonText: "Export",
    action: "create",

    fields: [
      {
        identifier: "company_id",
        label: "Company",
        accessor: "company_id",
        placeholder: "Select Company to Export its Products",
        required: true,
        lgCol: 12,
        mdCol: 12,
        type: "reference",
        isSearchable: true,
        optionsEntity: {
          name: "company",
          labelField: "name",
          valueField: "id",
        },
      },
      {
        identifier: "nationality",
        label: "Products Nationality",
        accessor: "nationality",
        required: true,
        lgCol: 6,
        mdCol: 6,
        defaultValue: "both",
        type: "select",
        options: [
          {
            label: "National",
            value: "national",
          },
          {
            label: "International",
            value: "international",
          },
          {
            label: "Both",
            value: "both",
          },
        ],
      },
      {
        identifier: "products_type",
        label: "Products Type",
        accessor: "products_type",
        required: true,
        lgCol: 6,
        mdCol: 6,
        defaultValue: "both",
        type: "select",
        options: [
          {
            label: "Retailed",
            value: "retailed",
          },
          {
            label: "Owned",
            value: "owned",
          },
          {
            label: "Both",
            value: "both",
          },
        ],
      },
    ],
    requestConfig: {
      url: `/${authConfig.serverName}/company_products_reports`,
      method: "post",
      entityIdentifier: "id",
      useFormData: true,
      authenticated: true,
      payloadWrapper: {
        name: "company_products_reports",
        fields: ["company_id", "nationality", "products_type"],
      },
      successMessage: "Your Report is Being Processed",
      errorMessage: "Couldn't Generate Report, Something Went Wrong",
    },
  });

import React from "react";
import WarningMessage from "../components/warningMessage";
const updateGcpsAction = [
  {
    identifier: "update_gcps",
    type: "formModal",
    active: (dataObject) => dataObject?.company_type == "account" && dataObject?.inactive == false,
    label: "Update Gcps",
    entityConfig: {
      name: "company",
      fieldsOverrides: {
        warning_message: {
          formComponent: () => (
            <>
              <WarningMessage message="Only active GCPs will be updated" />
            </>
          ),
          hideInEdit: false,
          lgCol: 12,
          mdCol: 12,
        },
        error_message: {
          hideInEdit: false,
          lgCol: 12,
          mdCol: 12,
        },
        active_to: {
          lgCol: 12,
          mdCol: 12,
        },
      },
      referencedEntityField: "id",
      entityOverrides: {
        isFullWidth: true,
        customEditEndpoint: ({ entity, data, id }) => {
          return `${entity.endpoint}/${id}/gcps`;
        },
        customNotifications: {
          edit: {
            error: {
              title: "Gcps Update Error",
              message: "Something went wrong while updating the Gcps",
            },
            success: {
              title: "Success",
              message: "Gcps Updated Successfully",
            },
          },
        },
      },
    },
    formAction: "edit",
  },
];
export default updateGcpsAction;

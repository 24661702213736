import { Entity } from "@thewebops/admin-panel";
export default authConfig =>
  new Entity({
    name: "editProfile",
    title: { en: "Edit your Profile", ar: "تعديل الحساب" },
    path: "/editProfile",
    actionLabel: { en: "Edit your profile", ar: "تعديل الحساب" },
    action: "edit",
    submitButtonText: "Change",
    fields: [
      {
        identifier: "name",
        label: { en: "Name", ar: "الاسم" },
        accessor: "name",
        type: "string",
        required: true,
      },
      {
        identifier: "email",
        label: "Email",
        accessor: "email",
        type: "email",
      },
    ],
    requestConfig: {
      url: `/${authConfig.serverPluralName}`,
      method: "put",
      saveToLocalStorage: true,
      useFormData: true,
      entityIdentifier: "id",
      successRedirectPath: "/app",
      payloadWrapper: {
        name: "admin",
        fields: ["name", "email"],
      },
    },
  });

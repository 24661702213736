import { createActions } from "@thewebops/admin-panel";

const actions = {
  publishProduct: createActions("product/publish"),
  exportProducts: createActions("product/exportProducts"),
  downloadImportTemplate: createActions(`product/downloadImportTemplate`),
  downloadReconcileTemplate: createActions(`product/downloadReconcileTemplate`),
};

export default actions;

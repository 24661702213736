import { Entity } from "@thewebops/admin-panel";
import moment from "moment";

export default new Entity({
  name: "importedSheet",
  pluralName: "imported_sheets",
  pathName: "imported_sheets",
  endpoint: "/admin/imported_sheets",
  serverName: "imported_sheet",
  serverPluralName: "imported_sheets",
  displayName: "Imported Sheets",
  fields: [
    {
      identifier: "file_name",
      label: "File Name",
      accessor: "file_name",
      inList: true,
      type: "string",
      usedToShow: true,
    },
    {
      identifier: "status",
      label: "Status",
      accessor: "status",
      inList: true,
      type: "string",
    },
    {
      identifier: "sheet_type",
      label: "Type",
      accessor: "sheet_type",
      inList: true,
      type: "string",
    },
    {
      identifier: "created_at",
      label: "Created At",
      accessor: (dataObject) => moment(dataObject.created_at).format("DD-MM-YYYY hh:mm A"),
      inList: true,
      type: "string",
    },
    {
      identifier: "created_by",
      label: "Created by",
      accessor: (dataObject) => dataObject?.creator?.admin?.name,
      inList: true,
      type: "string",
    },
    {
      identifier: "error_message",
      label: "Errors",
      accessor: "error_message",
      type: "string",
    },
    {
      identifier: "remaining_records_file",
      label: "Remaining Records File",
      accessor: "remaining_records_file",
      inList: false,
      type: "file",
    },
    {
      identifier: "output_file",
      label: "Output File",
      accessor: "output_file",
      inList: false,
      type: "file",
    },
    {
      identifier: "file",
      label: "Uplodaed File",
      accessor: "file",
      inList: false,
      type: "file",
    },
  ],
  filters: [
    {
      label: "User",
      name: "admin_id",
      type: "select",
      selectConfig: {
        optionsType: "dynamic",
        optionsEntity: {
          name: "admin",
          labelField: "name",
          valueField: "id",
        },
        isSearchable: true,
      },
    },
    {
      label: "Type",
      name: "sheet_type",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { label: "Product", value: "product" },
          { label: "Delete Products", value: "destroy_products" },
          { label: "Brand", value: "brand" },
          { label: "Category", value: "category" },
          { label: "Reconcilation", value: "reconcile" },
          { label: "Gcp", value: "gcps" },
        ],
      },
    },
    {
      label: "Status",
      name: "status",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { label: "Processing", value: "processing" },
          { label: "Processed", value: "processed" },
          { label: "Failed", value: "failed" },
          { label: "Contains Failed Records", value: "contains_failed_records" },
        ],
      },
    },
    {
      label: "Start Date",
      name: "start_date",
      type: "date",
    },
    {
      label: "End Date",
      name: "end_date",
      type: "date",
    },
  ],
});

import React from "react";
import { Alert } from "antd";

const ErrorMessage = ({ errors }) => {
  const errorMessage = errors?.update_gcps || errors?.migrate_gcps;

  return errorMessage ? <Alert message="Error" description={errorMessage} type="error" banner /> : "";
};

export default ErrorMessage;

import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "userApiAttribute",
  pluralName: "user_api_attributes",
  pathName: "user_api_attributes",
  endpoint: "/admin/user_api_attributes",
  serverName: "user_api_attribute",
  serverPluralName: "user_api_attributes",
  displayName: "User Api Attributes",
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  allowDelete: (dataObject) => (dataObject?.is_assigned === true ? false : true),
  allowEdit: (dataObject) => (dataObject?.is_assigned === true ? false : true),
  titleField: "user api attribute",
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "name",
      label: { en: "Name", ar: "الاسم" },
      accessor: "name",
      inList: true,
      usedToShow: true,
      type: "string",
      required: true,
    },
    {
      identifier: "data_type",
      label: { en: "Data Type", ar: "النوع" },
      accessor: "data_type",
      placeholder: "Select a Data Type",
      inList: true,
      type: "select",
      options: [
        { label: "Date", value: "date" },
        { label: "String", value: "string" },
        { label: "Text", value: "text" },
        { label: "Number", value: "integer" },
      ],
      required: true,
    },
  ],
});

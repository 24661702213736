import { Entity } from "@thewebops/admin-panel";
import CompaniesField from "./components/companiesField";
import CompaniesSheet from "./components/companiesSheet";

export default (authConfig) =>
  new Entity({
    identifier: "companyProductCount",
    name: "exportProductCount",
    title: "Export Products Count",
    subTitle: "Export a Sheet Contains Companies with Each Company Corresponding Products Count",
    path: "exportProductCounts",
    isFullWidth: true,
    submitButtonText: "Export",
    action: "create",
    fields: [
      {
        identifier: "export_type",
        label: "Export Type",
        accessor: "export_type",
        placeholder: "Select an Export Type",
        type: "select",
        dependee: true,
        resetDependants: ["export_all", "companies_ids", "companies_sheet"],
        required: true,
        options: [
          { label: "User Input", value: "user_input" },
          { label: "File Upload", value: "file_upload" },
        ],
        lgCol: 12,
        mdCol: 12,
      },
      {
        identifier: "companies_ids",
        label: "Companies Custom",
        accessor: "companies_ids",
        placeholder: "Select Companies to Export",
        formComponent: CompaniesField,
        required: true,
        dependsOn: "export_all",
        dependsOnValue: false,
        lgCol: 10,
        mdCol: 10,
      },
      {
        identifier: "export_all",
        label: "Export All",
        accessor: "export_all",
        dependsOn: "export_type",
        dependsOnValue: "user_input",
        type: "toggle",
        resetDependants: ["companies_ids"],
        dependee: true,
        defaultValue: true,
        toggleConfig: {
          on: true,
          off: false,
        },
        lgCol: 2,
        mdCol: 2,
      },
      {
        identifier: "nationality",
        label: "Nationality",
        accessor: "nationality",
        required: true,
        lgCol: 12,
        mdCol: 12,
        placeholder: "Select Nationality",
        type: "select",
        dependsOn: "export_type",
        dependsOnValue: ["user_input", "file_upload"],
        options: [
          {
            label: "National",
            value: "national",
          },
          {
            label: "International",
            value: "international",
          },
          {
            label: "Both",
            value: "both",
          },
        ],
      },
      {
        identifier: "companies_sheet",
        label: "Companies Sheet",
        accessor: "companies_sheet",
        required: true,
        type: "file",
        lgCol: 12,
        mdCol: 12,
        formComponent: CompaniesSheet,
        dependsOn: "export_type",
        dependsOnValue: "file_upload",
      },
    ],
    requestConfig: {
      url: `/${authConfig.serverName}/companies/export_product_counts`,
      method: "post",
      entityIdentifier: "id",
      useFormData: true,
      authenticated: true,
      payloadWrapper: {
        name: "export_product_count",
        fields: ["companies_ids", "nationality", "export_all", "export_type", "companies_sheet"],
      },
      successMessage: "Export Sheet Successfully Created",
      errorMessage: "Couldn't Create Export Sheet, Something Went Wrong",
    },
  });

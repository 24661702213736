import actions from "./actions";

const reducers = {
  [actions.publishAllProducts.request]: (state, action) => {
    state.loading = true;
  },
  [actions.publishAllProducts.success]: (state, action) => {
    const { entity, company } = action.payload;
    state.loading = false;

    const index = state.data.findIndex((el) => entity.compareIdentifierValue(el, company.id));
    state.data[index] = company;
  },
  [actions.publishAllProducts.failure]: (state, action) => {
    state.loading = false;
    state.errors = action.payload || { message: "there was an error" };
  },

  [actions.downloadImportTemplate.request]: (state, action) => {
    state.loading = true;
  },
  [actions.downloadImportTemplate.success]: (state, action) => {
    state.loading = false;
  },
  [actions.downloadImportTemplate.failure]: (state, action) => {
    state.loading = false;
  },
  [actions.exportCompanies.request]: (state, action) => {
    state.loading = true;
  },
  [actions.exportCompanies.success]: (state, action) => {
    state.loading = false;
  },
  [actions.exportCompanies.failure]: (state, action) => {
    state.loading = false;
    state.errors = action.payload || { message: "there was an error" };
  },
};

export default reducers;

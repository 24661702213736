import { takeLatest, call, put, select } from "redux-saga/effects";
import { request } from "@thewebops/admin-panel";
import actions from "./actions";
import i18n from "i18next";
import { showNotification, filtersToAttributes, getEntity } from "@thewebops/admin-panel";
import { saveAs } from "file-saver";

function* publishProduct(action) {
  const { item, entity } = action.payload;
  const { success, failure } = actions.publishProduct;
  try {
    const response = yield call(request, {
      url: `/admin/products/${item.id}/toggle_published`,
      method: "put",
      authenticated: true,
    });

    yield put(success({ entity, product: response.data.product }));
    showNotification(null, "success", i18n.t("notifications:editSuccess"));
  } catch (error) {
    yield put(failure());
    showNotification(null, "error");
  }
}

function* exportProducts(action, state) {
  const { entity, customEntityName } = action.payload;
  const { success, failure } = actions.exportProducts;
  try {
    const storeEntity = yield select(getEntity, customEntityName || entity.pluralName);

    let { start_date, end_date, published, brand_ids, category_ids, search_type, reconcile_status, product_type } = {
      ...filtersToAttributes(entity, { ...storeEntity, ...action.payload }),
    };
    yield call(request, {
      url: "admin/export_sheets",
      data: {
        start_date,
        end_date,
        published,
        brand_ids,
        category_ids,
        search_type,
        reconcile_status,
        product_type,
        query: storeEntity.query,
        export_type: "products",
      },
      method: "post",
      authenticated: true,
    });
    yield put(success());
    showNotification(null, "success", i18n.t("notifications:export"));
  } catch (error) {
    yield put(failure());

    showNotification(null, "error");
  }
}

function* downloadImportTemplate(action) {
  const { success, failure } = actions.downloadImportTemplate;
  try {
    const response = yield call(request, {
      url: `admin/products.xlsx`,
      method: "get",
      authenticated: true,
      isDownloading: true,
    });
    yield call(saveAs, response.data, "import_template.xlsx");
    yield put(success());
  } catch (error) {
    showNotification(null, "error", JSON.stringify(error.message));
    yield put(failure());
  }
}

function* downloadReconcileTemplate(action) {
  const { success, failure } = actions.downloadReconcileTemplate;
  try {
    const response = yield call(request, {
      url: `admin/products/reconciliations.xlsx`,
      method: "get",
      authenticated: true,
      isDownloading: true,
    });
    yield call(saveAs, response.data, "reconcile_template.xlsx");
    yield put(success());
  } catch (error) {
    showNotification(null, "error", JSON.stringify(error.message));
    yield put(failure());
  }
}
function* watchPublishProduct() {
  const { request } = actions.publishProduct;
  yield takeLatest(request, publishProduct);
}

function* watchEntityExportProducts() {
  const { request } = actions.exportProducts;

  yield takeLatest(request, exportProducts);
}

function* watchEntityDownloadImportTemplate() {
  const { request } = actions.downloadImportTemplate;
  yield takeLatest(request, downloadImportTemplate);
}

function* watchEntityDownloadReconcileTemplate() {
  const { request } = actions.downloadReconcileTemplate;
  yield takeLatest(request, downloadReconcileTemplate);
}

export default [
  watchPublishProduct,
  watchEntityExportProducts,
  watchEntityDownloadImportTemplate,
  watchEntityDownloadReconcileTemplate,
];

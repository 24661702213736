import React, { Fragment, useState, useEffect } from "react";
import { Select } from 'antd';
import { getEntity, entitiesActions } from "@thewebops/admin-panel";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash/debounce";
import companyEntity from "entities/Company"
import { Form } from 'antd';
import { useTranslation } from "react-i18next";


const CompaniesField = ({field, label, onChange, state, data, errors, value, excludeDay, format, ...props }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("companiesField");
  const { data: companies, nextPage } = useSelector(state => getEntity(state, companyEntity.pluralName));

  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [customConfigs, setCustomConfigs] = useState({
    query: "",
    page: 1
  });

  const getCompaniesData = () => {
    const { list: { request: requestAction } } = entitiesActions[companyEntity.name]
    dispatch(requestAction({ entity: companyEntity, page: customConfigs.page, query: customConfigs.query }))
  }

  const onScroll = event => {
    const { scrollTop, scrollHeight, offsetHeight } = event.target;
    if (Math.ceil(scrollTop + offsetHeight) >= scrollHeight && nextPage) {
      setCustomConfigs({ ...customConfigs, page: nextPage});
    }
  };

  const onSearch = search => {
    setFilteredCompanies([]);
    setCustomConfigs({ page: 1, query: search });
  };

  const onBlur = () => {
    onSearch("")
  }

  useEffect(() => {
    getCompaniesData()
  }, [customConfigs])

  useEffect(() => {
    setFilteredCompanies([...filteredCompanies, ...companies])
  }, [companies])
  
  const getCompanies = () => filteredCompanies.map(company => { return { value: company.id, label: company.name } })

  const handleChange = (data, option) => {
    setSelectedCompanies(option);
    onChange(field, data)
  };

  return (
    <Fragment>
      <Form.Item
        name="url"
        style={{ fontWeight: "bold" }}
        label={ field.label }
        labelAlign={"left"}
        rules={[{ required: true }]}
      >
      <Select
      value={selectedCompanies.map(c => c.value)}
      required={true}
      showSearch={true}
      mode="multiple"
      notFoundContent={<div>{t("select:noOptions")}</div>}
      dropdownRender={menu => (
        <Fragment>
          {menu}
        </Fragment>
      )}
      optionFilterProp="label"
      placeholder={field.placeholder}
      style={{ width: '100%' }}
      onChange={handleChange}
      options={getCompanies()}
      onPopupScroll={onScroll}
      onSearch={debounce(onSearch, 500)}
      filterOption={false}
      allowClear
      onBlur={onBlur}
    />
    {errors["companies_ids"] && <div style={{ color: "#ff4d4f" }}>{
      errors["companies_ids"] === "requiredFields"? "This field is required" : errors["companies_ids"]
    }</div>}
    </Form.Item>
    </Fragment>
  );
};


export default CompaniesField;

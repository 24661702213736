import actions from "./actions";

const reducers = {

  [actions.publishProduct.request]: (state, action) => {
    state.loading = true;
  },
  [actions.publishProduct.success]: (state, action) => {
    const { entity, product } = action.payload
    state.loading = false;

    const index = state.data.findIndex(el => entity.compareIdentifierValue(el, product.id));
    state.data[index] = product;
  },
  [actions.publishProduct.failure]: (state, action) => {
    state.loading = false;
    state.errors = action.payload || { message: "there was an error" };
  },
  [actions.exportProducts.request]: (state, action) => {
    state.loading = true;
  },
  [actions.exportProducts.success]: (state, action) => {
    state.loading = false;
  },
  [actions.exportProducts.failure]: (state, action) => {
    state.loading = false;
    state.errors = action.payload || { message: "there was an error" };
  },
  [actions.downloadImportTemplate.request]: (state, action) => {
    state.loading = true;
  },
  [actions.downloadImportTemplate.success]: (state, action) => {
    state.loading = false;
  },
  [actions.downloadImportTemplate.failure]: (state, action) => {
    state.loading = false;
  },

};

export default reducers;

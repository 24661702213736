import login from "./login";
import forgetPassword from "./forgetPassword";
import resetPassword from "./resetPassword";
import completeProfile from "./completeProfile";
import resendConfirmationEmail from "./resendConfirmationEmail";
import editProfile from "./editProfile";
import companyProductsReports from "./companyProductsReports";
import companyProductCount from "./companyProductCount";

const authConfig = {
  name: "admin",
  pluralName: "admins",
  pathName: "admins",
  serverName: "admin",
  serverPluralName: "admins",
  displayName: "Admin",
  endpoint: "admin/admins",
  customShowEndpoint: "self",
  saveAuthHeadersOnce: false,
  enableAccountCreation: false,
};

const authUserDefinedEntities = [
  login,
  forgetPassword,
  resetPassword,
  completeProfile,
  resendConfirmationEmail,
  editProfile,
  companyProductsReports,
  companyProductCount,
];

export { authConfig, authUserDefinedEntities };

import actions from "./actions";
import reducers from "./reducers";
import sagas from "./sagas";
import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "admin",
  pluralName: "admins",
  pathName: "admins",
  endpoint: "/admin/admins",
  serverName: "admin",
  serverPluralName: "admins",
  displayName: { en: "Admins", ar: "المدراء" },
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  customActions: actions,
  customReducers: reducers,
  customSagas: sagas,
  entityIdentifier: "id",
  rowActions: [
    {
      label: { en: "Reset Password", ar: "اعادة ضبط كلمة السر" },
      name: "sendForgetPassword",
    },
  ],
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInEdit: true,
    },
    {
      identifier: "name",
      label: { en: "Name", ar: "الاسم" },
      accessor: "name",
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "email",
      label: { en: "Email", ar: "بريد الالكتروني" },
      accessor: "email",
      inList: true,
      type: "email",
      disabled: (action) => action === "edit",      
      required: true,
    },
    {
      identifier: "role",
      label: { en: "Role", ar: "دور" },
      accessor: "role",
      inList: true,
      type: "select",
      hideInEdit: true,
      useHumanizer: true,
      options: [
        {
          value: "super_admin",
          label: "Super Admin",
        },
        {
          value: "api_admin",
          label: "Api Admin",
        },
      ],
    },
  ],
});

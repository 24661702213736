import admin from "./Admin";
import product from "./Product";
import brand from "./Brand";
import category from "./Category";
import user from "./User";
import report from "./Report";
import dropdownUser from "./DropdownUser";
import dropdownCategory from "./DropdownCategory";
import salesChannel from "./SalesChannel";
import gcp from "./Gcp";
import importedSheet from "./ImportedSheet";
import userAnalytic from "./UserAnalytic";
import categoryAnalytic from "./CategoryAnalytic";
import deletedProduct from "./DeletedProduct";
import exportSheet from "./ExportSheet";
import dailyCheckGtinReport from "./DailyCheckGtinReport";
import countAnalytic from "./CountAnalytics";
import gcpAnalytic from "./GcpAnalytics";
import company from "./Company";
import organization from "./Organization";
import userApiAttribute from "./UserApiAttribute";

const userDefinedEntities = {
  admin,
  product,
  brand,
  category,
  user,
  report,
  salesChannel,
  gcp,
  dropdownUser,
  dropdownCategory,
  importedSheet,
  userAnalytic,
  categoryAnalytic,
  gcpAnalytic,
  deletedProduct,
  countAnalytic,
  exportSheet,
  dailyCheckGtinReport,
  company,
  organization,
  userApiAttribute,
};

const groups = [
  {
    name: "ungrouped",
    entities: [dropdownUser, dropdownCategory],
  },
  {
    name: "Product",
    entities: [product, brand, category, gcp, deletedProduct, company],
  },
  {
    name: "Analytics",
    entities: [report, userAnalytic, categoryAnalytic, gcpAnalytic, countAnalytic],
  },
  {
    name: "Entities",
    entities: [admin, user, salesChannel, organization, userApiAttribute],
  },
  {
    name: "Sheets",
    entities: [importedSheet, , exportSheet, dailyCheckGtinReport],
  },
];
export { userDefinedEntities, groups };

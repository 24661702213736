import { Entity } from "@thewebops/admin-panel";
import moment from "moment";
import actions from "./actions";
import reducers from "./reducers";
import sagas from "./sagas";

export default new Entity({
  name: "category",
  pluralName: "categories",
  pathName: "categories",
  endpoint: "/admin/categories",
  serverName: "category",
  serverPluralName: "categories",
  displayName: "Categories",
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  showSearch: true,
  import: {
    enabled: true,
    extensions: ["xls", "csv", "application/vnd.openxmlformats-officedocument", "xlsx"],
    paramName: "file",
    method: "post",
    endpoint: "/admin/categories/import",
  },
  customActions: actions,
  customReducers: reducers,
  customSagas: sagas,
  allowExport: true,
  export: {
    enabled: true,
    extension: "xlsx",
    fileName: "categories",
  },
  globalActions: [
    {
      label: { en: "Download import sheet Template", ar: "تنزيل نموذج" },
      name: "downloadImportTemplate",
    },
  ],
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "name",
      label: { en: "English Name", ar: "الاسم الانجليزي" },
      accessor: "name",
      inList: true,
      usedToShow: true,
      type: "string",
      required: true,
    },
    {
      identifier: "arabic_name",
      label: { en: "Arabic Name", ar: "الاسم العربي" },
      accessor: "arabic_name",
      inList: true,
      usedToShow: true,
      type: "string",
      required: true,
    },
    {
      identifier: "code",
      label: { en: "Code", ar: "الكود" },
      accessor: "code",
      inList: true,
      usedToShow: true,
      type: "string",
      required: true,
    },
    {
      identifier: "category_type",
      label: { en: "Type", ar: "النوع" },
      accessor: "category_type",
      inList: true,
      type: "select",
      required: true,
      dependee: true,
      disabled: (action) => action === "edit",
      options: [
        {
          value: "segment",
          label: "Segment",
        },
        {
          value: "family",
          label: "Family",
        },
        {
          value: "class",
          label: "Class",
        },
        {
          value: "brick",
          label: "Brick",
        },
      ],
    },
    {
      identifier: "parent_id",
      label: { en: "Parent Category", ar: "التصنيف الاساسي" },
      accessor: (dataObject) => dataObject?.parent?.name,
      formAccessor: (dataObject) => dataObject?.parent?.id,
      isSearchable: true,
      inList: true,
      type: "reference",
      required: true,
      optionsEntity: {
        name: "category",
        labelField: "name",
        valueField: "id",
        displayAsLink: true,
        filterParams: [
          {
            name: "child_type",
            value: ["state", "category_type"],
            type: "both",
          },
        ],
      },
      dependsOn: "category_type",
      dependsOnValue: ["family", "class", "brick"],
    },
    {
      identifier: "created_at",
      label: { en: "Created at", ar: "أنشئت في" },
      accessor: (dataObject) => moment(dataObject.created_at).format("DD-MM-YYYY hh:mm A"),
      inList: true,
      type: "date",
      hideInEdit: true,
      hideInCreate: true,
    },
    {
      identifier: "updated_at",
      label: { en: "Updated at", ar: "تم التحديث في" },
      accessor: (dataObject) => moment(dataObject.updated_at).format("DD-MM-YYYY hh:mm A"),
      inList: true,
      type: "date",
      hideInEdit: true,
      hideInCreate: true,
    },
  ],
  filters: [
    {
      label: { en: "Type", ar: "النوع" },
      name: "category_type",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { label: "Segment", value: "segment" },
          { label: "Family", value: "family" },
          { label: "Class", value: "class" },
          { label: "Brick", value: "brick" },
        ],
      },
    },
  ],
});

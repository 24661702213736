import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "userAnalytic",
  pluralName: "user_analytics",
  pathName: "user_analytics",
  endpoint: "admin/user_analytics",
  serverName: "user_analytic",
  serverPluralName: "user_analytics",
  displayName: "User Analytics",
  titleField: "User Analytics",
  fields: [
    {
      identifier: "result",
      label: "Result",
      accessor: (dataObject) => dataObject.result || "-",
      inList: true,
      type: "string",
    },
  ],
  filters: [
    {
      label: "Type",
      name: "type",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { label: "Login Count", value: "login" },
          { label: "Latest Count", value: "product_latest" },
          { label: "Products Count", value: "get_all_products" },
          { label: "Fetched Products Count", value: "fetched_products_count" },
          { label: "Check Gtin", value: "check_gtin" },
          { label: "IPs used", value: "ip" },
          { label: "List Categories", value: "list_categories" },
          { label: "Latest Categories", value: "latest_categories" },
        ],
      },
    },
    {
      label: "User",
      name: "user_id",
      type: "select",
      selectConfig: {
        optionsType: "dynamic",
        optionsEntity: {
          name: "dropdownUser",
          labelField: "name",
          valueField: "id",
        },
      },
    },
    {
      label: "Start Date",
      name: "start_date",
      type: "date",
    },
    {
      label: "End Date",
      name: "end_date",
      type: "date",
    },
  ],
});

import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "countAnalytic",
  pluralName: "countAnalytics",
  pathName: "count_analytics",
  endpoint: "admin/count_analytics",
  serverName: "count_analytic",
  serverPluralName: "count_analytics",
  displayName: "Count Analytics",
  fields: [
    {
      identifier: "result",
      label: "Result",
      accessor: "result",
      inList: true,
      type: "string",
    },
  ],
  filters: [
    {
      label: "Entity",
      name: "entity_name",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: "product", label: { en: "Products", ar: "منتجات" } },
          { value: "category", label: { en: "Categories", ar: "فئات" } },
          { value: "brand", label: { en: "Brands", ar: "ماركات" } },
          { value: "user", label: { en: "Users", ar: "مستخدمين" } },
          { value: "admin", label: { en: "Admins", ar: "مسؤلين" } },
          { value: "gcp", label: { en: "GCP", ar: "GCP" } },
        ],
      },
    },
  ],
});

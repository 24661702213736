import { Entity } from "@thewebops/admin-panel";
import moment from "moment";

export default new Entity({
  name: "exportSheet",
  pluralName: "exportSheets",
  pathName: "export_sheets",
  endpoint: "admin/export_sheets",
  serverName: "export_sheet",
  serverPluralName: "export_sheets",
  displayName: "Export Sheets",
  allowDelete: true,
  showRowActions: true,
  rowActions: [
    {
      label: "Download Sheet",
      active: (dataObject) => dataObject && dataObject.file_url,
      customFunction: (dispatch, item) => {
        var win = window.open(item.file_url, "_blank");
        win.focus();
      },
    },
  ],
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
    },
    {
      identifier: "status",
      label: "Status",
      accessor: "status",
      type: "string",
      inList: true,
    },
    {
      identifier: "sheet_type",
      label: "Type",
      accessor: "sheet_type",
      type: "string",
      inList: true,
    },
    {
      identifier: "created_at",
      label: "Created At",
      accessor: (dataObject) => moment(dataObject.created_at).format("DD-MM-YYYY hh:mm A"),
      inList: true,
      type: "string",
    },
    {
      identifier: "updated_at",
      label: "Updated At",
      accessor: (dataObject) => moment(dataObject.updated_at).format("DD-MM-YYYY hh:mm A"),
      inList: true,
      type: "string",
    },
    {
      identifier: "created_by",
      label: "Created by",
      accessor: (dataObject) => dataObject?.creator?.name,
      inList: true,
      type: "string",
    },
    {
      identifier: "metadata",
      label: "filters",
      accessor: "metadata",
      type: "json",
      inList: false,
      hideInShow: false,
    },
  ],
});

import { Entity } from "@thewebops/admin-panel";
import moment from "moment";
import actions from "./actions";
import reducers from "./reducers";
import sagas from "./sagas";

export default new Entity({
  name: "brand",
  pluralName: "brands",
  pathName: "brands",
  endpoint: "/admin/brands",
  serverName: "brand",
  serverPluralName: "brands",
  displayName: "Brands",
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  useFormData: true,
  showSearch: true,
  customActions: actions,
  customReducers: reducers,
  customSagas: sagas,
  import: {
    enabled: true,
    extensions: ["xls", "csv", "application/vnd.openxmlformats-officedocument", "xlsx"],
    paramName: "file",
    method: "post",
    endpoint: "/admin/brands/import",
  },
  allowExport: true,
  export: {
    enabled: true,
    extension: "xlsx",
    fileName: "brands",
  },
  globalActions: [
    {
      label: { en: "Download import sheet Template", ar: "تنزيل نموذج" },
      name: "downloadImportTemplate",
    },
  ],
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "name",
      label: { en: "Name", ar: "الاسم الانجليزي" },
      accessor: "name",
      inList: true,
      usedToShow: true,
      type: "string",
      required: true,
    },
    {
      identifier: "arabic_name",
      label: { en: "Arabic Name", ar: "الاسم العربي" },
      accessor: "arabic_name",
      inList: true,
      usedToShow: true,
      type: "string",
      valuesRejectedOnSubmit: [""],
    },
    {
      identifier: "logo",
      label: "Logo",
      accessor: "logo",
      inList: false,
      type: "image",
      required: true,
    },
    {
      identifier: "created_at",
      label: { en: "Created at", ar: "أنشئت في" },
      accessor: (dataObject) => moment(dataObject.created_at).format("DD-MM-YYYY hh:mm A"),
      inList: true,
      type: "date",
      hideInEdit: true,
      hideInCreate: true,
    },
    {
      identifier: "updated_at",
      label: { en: "Updated at", ar: "تم التحديث في" },
      accessor: (dataObject) => moment(dataObject.updated_at).format("DD-MM-YYYY hh:mm A"),
      inList: true,
      type: "date",
      hideInEdit: true,
      hideInCreate: true,
    },
  ],
});

import { Entity } from "@thewebops/admin-panel";

export default authConfig =>
  new Entity({
    name: "login",
    title: { en: "Login to your account", ar: "تسجيل الدخول للحساب" },
    subTitle: {
      en: `Enter the credentials associated with your ${authConfig.displayName} account.`,
      ar: `${authConfig.displayName}ادخل بيانات الحساب التابعة ل`,
    },
    logoTitle: "Governament Interface",
    path: "/login",
    actionLabel: { en: "Login", ar: "الدخول" },
    action: "create",
    submitButtonText: "Login",
    // isFullScreen: true,
    fields: [
      {
        label: { en: "Email", ar: "البريد الالكتروني" },
        required: true,
        type: "email",
        identifier: "email",
        accessor: "email",
      },
      {
        label: { en: "Password", ar: "كلمة المرور" },
        required: true,
        type: "password",
        identifier: "password",
        accessor: "password",
      },
      // {
      //   label: "",
      //   type: "date",
      //   identifier: "admin_panel",
      //   defaultValue: true,
      //   disabled: true,
      // },
    ],
    requestConfig: {
      url: `/${authConfig.serverPluralName}/sign_in`,
      method: "post",
      saveToLocalStorage: true,
      useFormData: true,
      customErrorKeys: ["message"],
    },
  });
